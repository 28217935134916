.sidebar {

    margin-left:0px;
    padding-bottom: 0px;
    overflow-y:scroll;
    width: 270px;
    height: 50vh;
    font-family: 'Montserrat', sans-serif;
    border-radius: 10px;
    left: 0;
    top: 200px;
    align-items: left;
    position: fixed;

}

@media screen and (max-width: 1280px){
    .channeladd{
        display:none;
        width: 100px;
        margin: 0px !important;
        margin-left: 10px !important;
    }
    .chanbtn{
        display:none;
        margin: 0px !important;
        margin-left: 10px !important;
        height: 38px;
    }
        .sidebar {

            margin-left:0px;
            padding-bottom: 0px;
            overflow-y:scroll;
            width: 190px;
            height: 50vh;
            font-family: 'Montserrat', sans-serif;
            border-radius: 10px;
            left: 0;
            top: 200px;
            align-items: left;
            position: fixed;

        }
    }
@media screen and (max-width: 1160px){
    .channeladd{
        display:none;
        width: 100px;
        margin: 0px !important;
        margin-left: 10px !important;
    }
    .chanbtn{
        display:none;
        margin: 0px !important;
        margin-left: 10px !important;
        height: 38px;
    }
        .sidebar {

            margin-left:0px;
            padding-bottom: 0px;
            overflow-y:scroll;
            width: 140px;
            height: 50vh;
            font-family: 'Montserrat', sans-serif;
            border-radius: 10px;
            left: 0;
            top: 200px;
            align-items: left;
            position: fixed;

        }
    }
@media screen and (max-width: 1020px){
    .channeladd{
        display:none;
        width: 100px;
        margin: 0px !important;
        margin-left: 10px !important;
    }
    .chanbtn{
        display:none;
        margin: 0px !important;
        margin-left: 10px !important;
        height: 38px;
    }
        .sidebar {
            display:none;
            margin-left:0px;
            padding-bottom: 0px;
            overflow-y:scroll;
            width: 140px;
            height: 50vh;
            font-family: 'Montserrat', sans-serif;
            border-radius: 10px;
            left: 0;
            top: 200px;
            align-items: left;
            position: fixed;

        }
    }

.sidebar:hover > ::-webkit-scrollbar{
    display:block!important;
}
::-webkit-scrollbar {
    display: none;
  width: 5px;
}
.channelselect:hover{
    background-color: #f0f0f0;
}
.channelselect:hover > .sidebarListItem > .radio{
    color: #3366ff;
}
/* Track */
::-webkit-scrollbar-track {

  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #667;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.sbrow{
    width:270px!important;
    margin-right: 0px!important;
    margin-left:0px!important;
    margin-top: 5px!important;
}
.channeladd{
    width: 200px;
    margin: 0px !important;
    margin-left: 10px !important;
}
.chanbtn{
    margin: 0px !important;
    margin-left: 10px !important;
    height: 38px;
}
.sidebarItem {

    align-items: left;
}

.channelref{
    text-decoration: none !important;
}
.radio{
    margin-right: 25px;

}
.sidebarList {
    margin-top: 20px;
    margin-left: 5px;
    list-style: none;
    margin-bottom: 30px;
    padding:0px!important;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sidebarListItem {

    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    margin-left: 20px;
    color: black;
    text-decoration: none;
    vertical-align: middle;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.channelselect{
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    cursor:pointer;
    width: 100%;
    height: 40px;
    margin:0px!important;

}
