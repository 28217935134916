.trending {
    display: flex;
    justify-content: center;
}
.heading1 {
    font-family: 'Montserrat';
    text-align: center;
    font-size: 5rem;

}
