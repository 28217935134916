.post {
    max-width:700px;
    /* margin: 0px 25px 40px 25px; */
    margin: auto;
    margin-bottom: 40px;
    background-color: white;
}

@media screen and (max-width:760px){
.post {
    max-width:580px !important;
    margin: 0px 25px 40px 25px;
    margin: auto;
    
    margin-bottom: 40px;
    background-color: white;
}
}

.rowborder:hover {
    border-radius: 8px;
    cursor:default;
    box-shadow: rgba(26, 26, 26, 0.1) 0px 1px 1px, rgba(26, 26, 26, 0.1) 0px 1px 5px, rgba(26, 26, 26, 0.1) 0px 2px 2px !important;

}
.rowborder {
    background-color: white;
    border-radius: 8px;
    cursor:default;
    box-shadow: rgba(26, 26, 26, 0.1) 0px 1px 1px, rgba(26, 26, 26, 0.1) 0px 2px 12px, rgba(26, 26, 26, 0.1) 0px 4px 5px !important;

}
.titlelink:hover {
    text-decoration: none;
}
.postTitle:hover {
    color: rgba(0,0, 0, .7);

}
.arrowtop{
    margin-left: 10px;
}
.arrow{
    font-size: 2rem;
    cursor:pointer;
    color: #333;
}
.arrowtop:hover {
    color: #33bb77;
}
.arrowbottom:hover {
    color: #ff5555;
}
.postImg {
    max-width: 100%;
    max-height: 180px;
    object-fit: cover;
    border-radius: 7px;
}

.postInfo {

    align-items: center;
    font-family: 'Montserrat', sans-serif;
}

.postChannels {

}
.postChannel {
    font-family: 'Montserrat', sans-serif;
    font-size: .9rem;
    color: #2222bb;
    line-height: 2rem;
    margin-top:25px;
    margin-right: 10px;
    cursor:pointer;

}
.postChannel:hover {
    text-decoration: underline;
}
.postDate {
    font-family: 'Source Serif Pro', serif;
    font-size: .9rem;
    font-style: italic;
    color:#999;
    margin-top: 15px;
    margin-right: 20px;
}

.postTitle {
    font-family: 'Open Sans', sans-serif;
    margin-top: 5px;
    cursor: pointer;
    font-size: 1.5rem;
    color: #444;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.postDesc {
    font-size: .9rem;
    font-family: 'Montserrat', sans-serif;
    color:#667;
    margin-top: 15px;
    margin-bottom: 5px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

}
