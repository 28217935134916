.top {
    width: 100%;
    height: 50px;
    background-color: #fff;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    z-index: 999;
}
.homelink:hover > .toparrowlink {
    color: #33bb77;
}
.homelink:hover > .bottomarrowlink {
    color: #ff5555 !important;
}
.toparrowlink, .bottomarrowlink {
    color: #222;
}


.topHome {
    width: 100%;
    height: 50px;
    background-color: #f7f7f7;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    z-index: 999;
}

.topLeft{
    flex:2;
    display:flex;
    align-items:left;

}
.topRight{

    align-items: right;

}
.topRighthome{

    align-items: right !important;
}
.topCenter {
    flex:6;
}
.topCenterhome {
    flex:7;
}
.topicon, .navicon, .drop:hover {
    font-size: 1.6rem;
    cursor: pointer;
    color: #333;
}
.topicon:hover {
    color:#667;
}
.rightlist {
    display: flex;
    justify-content: right;
    align-items: center;
    list-style: none;
    margin-right: 20px;
    margin-top: 10px;
    padding:0px;
}
.rightlisthome {
    display: flex;
    justify-content: right;
    align-items: center;
    list-style: none;
    margin-right: 50px;
    margin-top: 10px;
    padding:0px;
}
.leftlist {
    display: flex;
    justify-content: left;
    align-items: left;
    list-style: none;
    margin-left:20px;
    margin-top: 10px;
    padding:0px;
}
.listitem {
    margin-top:10px;
    margin-right: 20px;
}
.listitemhome {
    display: flex;
    justify-content: center;
    align-items: right;
    list-style: none;
    padding:0px;
    margin-left: 20px;
}

.pin:hover {
    color: #7af;
}
.flame:hover {
    color: #f97;
}
.pen:hover {
    color: #ffcf00;
}
.friends:hover {
    color: #a7f;
}
.envelope:hover {
    color: #f78;
}
.cog:hover {
    color: #555;
}

.button:hover > .cog {
    color: #000;
}
.house:hover {
    color: #777;
}
.magnifyingGlass:hover {
    color: #777;
}
