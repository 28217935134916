.settings{
    display: block;
}
.settingsWrapper {
    flex: 9;
    display: flex;
    padding:20px;
    justify-content: center;
    align-items: center;
}
.notification {
    align-items: center;
    justify-content: center;
}
.settingsTitle {

    flex-direction: row;
}
.settingsUpdateTitle {
    font-size: 30px;
    margin-bottom: 20px;
    color: lightcoral;
}
.settingsDeleteTitle {
    color: red;
    font-size: 12px;
    cursor: pointer;
}
.settingsHeading{
    font-family: 'Montserrat', sans-serif;
    font-size: 2.99rem;
}
.col {
    margin-top: 20px;
}

.settingsForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
}
.settingsPP {
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.settingsPP > img {
    width: 120px;
    height: 120px;
    border-radius: 20px;
    object-fit: cover;
}
.settingsPPIcon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: lightcoral;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
}
.settingsForm > label {
    font-size: 20px;
    margin-top: 20px;
}
.settingsForm > input {
    color: gray;
    margin: 10px 0;
    height: 30px;
    border: none;
    border-bottom: 1px solid lightgray;
}
.settingsSubmit {
    width: 150px;

    padding: 10px;
    margin-top: 20px;

}
