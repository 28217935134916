.channelposts {
    display: flex;
    justify-content: center;
}
.heading1 {
    font-family: 'Montserrat';
    text-align: center;
    font-size: 5rem;
    
}
.zipadd{
    width: 200px;
    margin: 0px !important;
    margin-left: 10px !important;
}
.zipbtn{
    margin: 0px !important;
    margin-left: 10px !important;
    height: 38px;
}
