.write {
    padding-top: 50px;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.writeImg {
    object-fit: cover;
    height: 250px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
    width: 50vw;
    margin:auto;
    margin-bottom: 20px;

}
.writeFormGroup {

    display: flex;
    align-items: center;
}

.writeIcon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    color: #333;
    font-size: 30px;
    margin-bottom: 20px;

}
.writeIcon:hover {
    color: #777;
}

.writeInput {
    padding: 0px;
    font-size: 16px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid;
    width: 50vw;
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
}
.channelInput {
    padding: 0px;
    font-size: 16px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid;
    width: 12vw;
    margin-bottom: 20px;
    margin-right: 25px;
    font-family: 'Open Sans', sans-serif;
}

.writeInput:focus, .channelInput:focus {
    outline: none;
}

.writeText {
    font-size: 1rem;
    height: 100px;
}

.writeSubmit {

    margin-top: 20px;
    right: 50px;



    cursor: pointer;

}
